import classNames from 'classnames';
import React, { memo, useCallback, useContext, useRef, useState } from 'react';

import useClickOutside from '../hooks/useClickOutside';
import trackEvent from '../lib/trackEvent';
import { NewsletterOpenerContext } from '../providers/NewsletterProvider';
import { isOpen, searchMenu } from '../scss/modules/search-menu.module.scss';
import LazySearch from './LazySearch';
import Menubar from './menubar';
import MobileMenuBar from './mobile-menubar';
import Newsletter from './newsletter';
import TabTrap from './tab-trap';

const Nav = ({ activeMenuItem }) => {
  const menuRef = useRef(null);
  const [navState, setNavState] = useState('closed');
  const setNewsletterOpen = useContext(NewsletterOpenerContext);
  useClickOutside(
    menuRef,
    useCallback(() => setNavState('closed'), []),
  );

  const onMoreClick = () => {
    setNavState((prevState) => {
      const newState = prevState === 'main' ? 'closed' : 'main';
      trackEvent('Mobile Nav Menu', newState === 'main' ? 'Open' : 'Close');
      return newState;
    });
  };

  const onSearchClick = () => {
    setNavState((prevState) => {
      const newState = prevState === 'search' ? 'closed' : 'search';
      if (newState === 'search') {
        document.querySelector('#search-menu .js-search-field')?.focus();
      }
      return newState;
    });
  };

  const onClose = () => {
    if (navState !== 'closed') {
      setNavState('closed');
      trackEvent('Mobile Nav Menu', 'Close');
    }
  };

  const onMenuLinkClick = () => {
    if (navState === 'main') {
      setNavState('closed');
      trackEvent('Mobile Nav Menu', 'Close');
    }
  };

  return (
    <nav className="main-nav__container" ref={menuRef}>
      <MobileMenuBar
        isMainNavOpen={navState === 'main'}
        isSearchOpen={navState === 'search'}
        onMenuItemClick={onMenuLinkClick}
        onMoreClick={onMoreClick}
        onSearchClick={onSearchClick}
        activeMenuItem={activeMenuItem}
      />
      <div className={classNames(searchMenu, navState === 'search' && isOpen)}>
        <div
          className="main-nav__search main-nav__list"
          id="search-menu"
          aria-hidden={navState !== 'search'}
        >
          {navState === 'search' && (
            <LazySearch className="grow" onResultClick={onMenuLinkClick} />
          )}
        </div>
      </div>
      <div
        className={classNames('main-nav js-main-nav', navState === 'main' && 'open')}
        id="nav-menu"
        aria-hidden={navState !== 'main'}
      >
        <LazySearch className="grow hidden 4xl:block" onResultClick={onMenuLinkClick} />
        <div className="main-nav__newsletter">
          <Newsletter onClick={setNewsletterOpen} />
        </div>
        <Menubar onClick={onMenuLinkClick} activeMenuItem={activeMenuItem} />
        {navState === 'main' && <TabTrap nextId="mobile-menu-more-trigger" />}
      </div>
    </nav>
  );
};

export default memo(Nav);
