import React from 'react';

import Link from './link';

export default () => (
  <header id="main-header" className="main-header js-logo">
    <span className="main-header__name flex justify-center">
      <Link className="main-header__link" to="/" id="logo">
        <svg
          className="main-header__logo"
          role="img"
          viewBox="0 0 300 100"
          width={300}
          height={100}
          preserveAspectRatio="xMidYMid meet"
          aria-labelledby="logotitle logodesc"
        >
          <title id="logotitle">Offbeat Budapest</title>
          <desc id="logodesc">Logo</desc>
          <use xlinkHref="#otb-logo" />
        </svg>
      </Link>
    </span>
  </header>
);
