// react function component:
import { graphql, useStaticQuery } from 'gatsby';
import React, { ReactNode } from 'react';
import MenuContext from '../contexts/MenuContext';
import { SiteMetaContext } from '../contexts/SiteMetaContext';
import { NewsletterProvider } from './NewsletterProvider';

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const homePage = useStaticQuery(graphql`
    query homePage {
      contentfulHomePage {
        id
        siteTitle: title
        headerMenuItems {
          ... on Node {
            ... on ContentfulToplists {
              slug: url
              title
              menuTitle
              shortTitle
            }
            ... on ContentfulArticles {
              slug: url
              title
              menuTitle
              shortTitle
            }
            ... on ContentfulRecipes {
              slug: url
              title
              menuTitle
              shortTitle
            }
            ... on ContentfulFeatures {
              slug: url
              title
              menuTitle
              shortTitle
            }
            ... on ContentfulAbout {
              slug: url
              title
              menuTitle
              shortTitle
            }
            ... on ContentfulCollection {
              slug: url
              title
              menuTitle
              shortTitle
            }
          }
        }
      }
    }
  `);

  const { siteTitle, headerMenuItems } = homePage.contentfulHomePage;
  return (
    <SiteMetaContext.Provider value={{ siteTitle }}>
      <MenuContext.Provider
        value={headerMenuItems.filter((menuItem: { slug: string | undefined }) => !!menuItem.slug)}
      >
        <NewsletterProvider>{children}</NewsletterProvider>
      </MenuContext.Provider>
    </SiteMetaContext.Provider>
  );
};
