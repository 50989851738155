import React, { useContext } from 'react';
import MenuContext from '../contexts/MenuContext';
import Link from './link';

export default () => (
  <ul className="main-footer__menu">
    {useContext(MenuContext).map(({
      slug, title, menuTitle, shortTitle,
    }) => (
      <li
        key={slug}
        className="main-footer__item"
      >
        <Link
          id={`nav-footer-${slug}`}
          to={`/${slug}/`}
          className="main-footer__menu-link"
        >
          {menuTitle || shortTitle || title}
        </Link>
      </li>
    ))}
  </ul>
);
