'use server';
import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/footer';
import Header from '../components/header';
import Svg from '../components/icons';
import Nav from '../components/navigation';
import { getActiveMenuItem } from '../lib/getActiveMenuItem';

import '../scss/global.scss';
import('../scss/common-deferred.scss');

const NewsletterModal = React.lazy(() => import('../components/newsletter-modal'));

const isSSR = typeof window === 'undefined';

const Layout = ({ children, data }) => {
  const activeMenuItem = getActiveMenuItem(data?.page);
  return (
    <>
      <Svg />
      <Header />
      <Nav activeMenuItem={activeMenuItem} />
      {children}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <NewsletterModal />
        </React.Suspense>
      )}
      <ScrollToTop />
      <Footer />
    </>
  );
};

export default Layout;
