import React from 'react';

import Link from './link';
import MenubarFooter from './menubar-footer';

export default function () {
  return (
    <footer className="main-footer">
      <div className="main-footer__container cf">
        <div className="main-footer__wrapper">
          <div className="main-footer__column">
            <Link
              className="main-footer__link"
              to="/"
            >
              <svg className="main-footer__logo" role="img" viewBox="0 0 300 100" width={300} height={100} preserveAspectRatio="xMidYMid meet" aria-labelledby="footerlogotitle footerlogodesc">
                <title id="footerlogotitle">Offbeat Budapest</title>
                <desc id="footerlogodesc">Logo</desc>
                <use xlinkHref="#otb-logo" />
              </svg>
            </Link>
          </div>
          <div className="main-footer__column">
            <p className="main-footer__label">Let us keep you updated</p>
            <a className="main-footer__social" href="https://www.facebook.com/offthebeatbudapest/" target="_blank" rel="noopener noreferrer">
              <svg className="main-footer__facebook" role="presentation" aria-label="Offbeat Budapest on Facebook">
                <title>Offbeat Budapest on Facebook</title>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-facebook" />
              </svg>
              <span>Facebook</span>
            </a>
            <a className="main-footer__social" href="https://www.instagram.com/offbeatbudapest/" target="_blank" rel="noopener noreferrer">
              <svg className="main-footer__instagram" role="presentation" aria-label="Offbeat Budapest on Instagram">
                <title>Offbeat Budapest on Instagram</title>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-instagram" />
              </svg>
              <span>Instagram</span>
            </a>
          </div>
          <div className="main-footer__column">
            <MenubarFooter />
          </div>
        </div>
        <p className="main-footer__copy">© - All rights reserved - created by: PIXELEPHANT &amp; AENEIS</p>
      </div>
    </footer>
  );
}
